
import { Options, Vue } from 'vue-class-component';
import { format } from 'date-fns';
import AbsencesCalendarTable from '@/components/App/AbsencesCalendarTable.vue'

import { useQuery } from '@vue/apollo-composable';
import { GetMonthsDocument, Month } from '@/graphql/generated-operations';

@Options({
  components: {
    AbsencesCalendarTable,
  },
})
export default class AbsencesCalendar extends Vue {

  months: Month[] = [];
  selectedMonth: Month | null = null;

  loading = true;

  created() {
    const { onResult: onResult } = useQuery(GetMonthsDocument);

    onResult(queryResult => {
      this.months = queryResult.data.getMonths;

      const currentMonth = format(new Date(), 'y-MM');
      this.months.map((month, index) => {
        if ( month.key == currentMonth) {
          this.selectedMonth = this.months[index];
        }
      });

      this.loading = false;
    });
  }

}
