
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import AppButton from '@/components/Common/AppButton.vue';
import { CheckIcon, CalendarIcon } from '@heroicons/vue/solid';
import { useApolloClient, useMutation, useQuery } from '@vue/apollo-composable';
import { useToast } from 'vue-toastification';
import { GetAbsenceDocument, AbsenceType, GetAbsenceTypesDocument, Time, SaveAbsenceDocument, AbsenceDayMinutesInput, GetAbsenceDaysTypesDocument } from '@/graphql/generated-operations';

import { DatePicker } from 'v-calendar';
import { format, addDays } from 'date-fns';
import { processResponseErrors } from '@/helpers/errors';
import router from '@/router';

const SELECT_ABSENCE_TYPE_PROMPT_ID = '-1';

@Options({
  components: {
    AppButton,
    CheckIcon, CalendarIcon,
    DatePicker,
  },
})
export default class Absence extends Vue {

  absenceID: string | undefined = undefined;

  contractID: string | undefined = undefined;

  date: {
    start: Date | null,
    end: Date | null,
  } = {
    start: null,
    end: null,
  };

  selectedAbsenceType: string = SELECT_ABSENCE_TYPE_PROMPT_ID;

  open = false;

  days = new Map<string, {
    hasTimePart: boolean,
    enabled: boolean,
    time: Time,
  }>();

  absenceTypes: AbsenceType[] = [];

  loading = true;

  created() {
    this.contractID = this.$route.params.contractID as string;
    this.absenceID = this.$route.params.absenceID as string;

    this.fetchAbsenceTypes();

    if ( this.absenceID ) {
      const { onResult } = useQuery(GetAbsenceDocument, {
        absenceID: this.absenceID
      });

      onResult(queryResult => {
        const absence = queryResult.data.getAbsence;

        this.date.start = new Date(absence.dateFrom);
        this.date.end = new Date(absence.dateTo);
        this.selectedAbsenceType = absence.absenceType.id;
        this.open = absence.open;


        (async () => {
          await this.initDays();

          for ( const absenceDay of absence.days ) {
            if ( absenceDay.minutes && absenceDay.minutes > 0 ) {
              const day = this.days.get(absenceDay.date);
              if (!day) continue;

              day.time.hours = Math.floor(absenceDay.minutes / 60);
              day.time.minutes = absenceDay.minutes % 60;
              day.enabled = absenceDay.minutes > 0;
            }
          }
        })();

        this.loading = false;
      });
    }
    else {
      this.loading = false;
    }
  }

  fetchAbsenceTypes()
  {
    const { onResult } = useQuery(GetAbsenceTypesDocument);

    onResult(queryResult => {
      this.absenceTypes = queryResult.data.getAbsenceTypes;

      const promptOption = {id: SELECT_ABSENCE_TYPE_PROMPT_ID, name: '-- Select absence type --'};
      this.absenceTypes = [promptOption, ...this.absenceTypes];
    });
  }

  @Watch('date', {deep: true})
  async initDays()
  {
    const days = new Map(this.days);
    days.clear();

    if ( !this.date.start || !this.date.end || this.date.start > this.date.end ) {
      return;
    }

    const query = await useApolloClient().client.query({
      query: GetAbsenceDaysTypesDocument,
      variables: {
        contractID: this.contractID!,
        dateFrom: format(this.date.start, 'y-MM-dd'),
        dateTo: format(this.date.end, 'y-MM-dd'),
      }
    });

    const absenceDaysTypes = query.data.getAbsenceDaysTypes;

    let dateIterator = new Date(this.date.start.getTime());

    while ( dateIterator <= this.date.end ) {
      const day = {
        hasTimePart: false,
        enabled: false,
        time: {
          hours: 0,
          minutes: 0,
        }
      };

      const dateKey = format(dateIterator, 'y-MM-dd');

      const absenceDayType = absenceDaysTypes.find(dayType => dayType.date == dateKey);

      if ( absenceDayType?.hasTimePart ) {
        day.hasTimePart = true;

        const oldDayTime = this.days.get(dateKey);

        if ( oldDayTime && oldDayTime.hasTimePart ) {
          day.time.hours = oldDayTime.time.hours;
          day.time.minutes = oldDayTime.time.minutes;

          if ( oldDayTime.enabled ) {
            day.enabled = true;
          }
        }
      }

      days.set(dateKey, day);

      dateIterator = addDays(dateIterator, 1);
    }

    this.days = days;
  }

  formatDate(date: Date)
  {
    return format(date, 'd. M. y');
  }

  parseNumber(number: unknown) : number | undefined
  {
    return Number.isInteger(number) ? Number(number) : undefined;
  }

  parseMinutes(time: Time)
  {
    const hours = this.parseNumber(time.hours);
    const minutes = this.parseNumber(time.minutes);

    let result = 0;
    if ( hours != undefined ) {
      result += hours * 60;
    }
    if ( minutes != undefined ) {
      result += minutes;
    }

    return result;
  }

  async onSubmit()
  {
    if ( !this.date.start || !this.date.end || this.date.start > this.date.end ) {
      useToast().error('Please set valid date range.');
      return;
    }

    if ( this.selectedAbsenceType == SELECT_ABSENCE_TYPE_PROMPT_ID ) {
      useToast().error('Please select absence type.');
      return;
    }

    const days: AbsenceDayMinutesInput[] = [];
    this.days.forEach((day, date) => {
      if (!day.enabled) return;

      days.push({
        date: date,
        minutes: day.time.hours * 60 + day.time.minutes,
      });
    })

    const { mutate: saveAbsence } = useMutation(SaveAbsenceDocument);
    const result = await saveAbsence({
      absenceID: this.absenceID,
      absence: {
        dateFrom: format(this.date.start, 'y-MM-dd'),
        dateTo: format(this.date.end, 'y-MM-dd'),
        contractID: this.contractID!,
        absenceType: this.selectedAbsenceType,
        open: this.open,
        daysMinutes: days,
      }
    });

    const isSuccess = processResponseErrors(result?.data?.saveAbsence);

    if ( isSuccess ) {
      useToast().success('Absences has been saved.');
      router.go(-1);
    }
  }

}
