
import { Options, Vue } from 'vue-class-component';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { UploadIcon, TruckIcon, CheckIcon, PlusIcon } from '@heroicons/vue/solid';
import ContractTable from '@/components/App/ContractTable.vue';
import ReportTable from '@/components/App/ReportTable.vue';
import AppButton from '@/components/Common/AppButton.vue';
import { format } from 'date-fns';

import { processResponseErrors } from '@/helpers/errors';

import FileSaver from 'file-saver';
import FileUpload, { VueUploadItem } from 'vue-upload-component';

import { useApolloClient, useQuery, useMutation } from '@vue/apollo-composable';
import { Contract, GetCsvMonthReportDocument, GetMonthsDocument, Month, ImportCsvContractTimeRecordsDocument, GetCsvContractTimeRecordsDocument } from '@/graphql/generated-operations';
import { useToast } from 'vue-toastification';

export interface ModalSettings {
  type: 'month' | 'date';
  contract: Contract;
  period: string;
  selectedMonth?: Month;
  selectedDate?: string;
}

@Options({
  components: {
    AppButton,
    ContractTable,
    ReportTable,
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
    UploadIcon, TruckIcon, CheckIcon, PlusIcon,
    FileUpload
  },
})
export default class Reports extends Vue {

  months: Month[] = [];
  selectedMonth: Month | null = null;

  reportModalIsOpen = false;
  modalSettings?: ModalSettings;
  modalRefreshKey = 0;

  csvModalIsOpen = false;
  csvImportFile: VueUploadItem | null = null;
  reportTableRefreshKey = 0;

  loading = true;

  created() {
    const { onResult: onResult } = useQuery(GetMonthsDocument);

    onResult(queryResult => {
      this.months = queryResult.data.getMonths;

      const currentMonth = format(new Date(), 'y-MM');
      this.months.map((month, index) => {
        if ( month.key == currentMonth) {
          this.selectedMonth = this.months[index];
        }
      });

      this.loading = false;
    });
  }

  openContractMonthModal(contract: Contract) {
    this.modalSettings = {
      type: 'month',
      contract: contract,
      period: this.selectedMonth!.name,
      selectedMonth: this.selectedMonth!,
    };

    this.reportModalIsOpen = true;
  }

  openContractDateModal(contract: Contract, date: string) {
    const d = new Date(date);
    this.modalSettings = {
      type: 'date',
      contract: contract,
      period: format(d, 'EEEE d.M.y'),
      selectedDate: format(d, 'y-MM-dd'),
    };

    this.reportModalIsOpen = true;
  }

  closeReportModal() {
    this.reportModalIsOpen = false;
  }

  async submitModal() {
    const reportTable = this.$refs.reportTable as ReportTable;
    const result = await reportTable.submitData();
    if ( result ) {
      useToast().success('Report has been saved.');
      this.reportModalIsOpen = false;
      this.modalRefreshKey++;
    }
  }

  async exportToCsv() {
    const query = await useApolloClient().client.query({
      query: GetCsvMonthReportDocument,
      variables: {
        month: this.selectedMonth!.key,
      }
    });

    const {name, content} = query.data.getCsvMonthReport;

    const blob = new Blob([content], {type: 'text/csv'});
    FileSaver.saveAs(blob, name);
  }

  openImportModal() {
    this.csvModalIsOpen = true;
  }

  closeImportModal() {
    this.csvModalIsOpen = false;
  }

  onInputFile(inputFile: VueUploadItem | undefined) {
    if ( !inputFile || inputFile.file === undefined ) return;

    this.csvImportFile = inputFile;
  }

  async importContractCsvFile() {
    if ( !this.csvImportFile || !this.csvImportFile.file ) {
      useToast().error('Please select valid CSV file.');
      return;
    }

    const data = this.csvImportFile.file;

    const { mutate: saveMonthTimeRecords } = useMutation(ImportCsvContractTimeRecordsDocument);
    const result = await saveMonthTimeRecords({
      contractID: this.modalSettings!.contract.id,
      month: this.selectedMonth!.key,
      content: await data.text(),
    });

    const isSuccess = processResponseErrors(result?.data?.importCsvContractTimeRecords);
    if ( !isSuccess ) {
      this.csvImportFile = null;
      return;
    }

    useToast().success('CSV file has been successfully imported.');
    this.reportTableRefreshKey++;
    this.modalRefreshKey++;
    this.csvModalIsOpen = false;
    this.csvImportFile = null;
  }

  async exportContractToCsv() {
    const query = await useApolloClient().client.query({
      query: GetCsvContractTimeRecordsDocument,
      variables: {
        contractID: this.modalSettings!.contract.id,
        month: this.selectedMonth!.key,
      }
    });

    const {name, content} = query.data.getCsvContractTimeRecords;

    const blob = new Blob([content], {type: 'text/csv'});
    FileSaver.saveAs(blob, name);
  }

}
