
import { ref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, ComboboxButton, TransitionRoot } from '@headlessui/vue';
import { XIcon, PlusIcon, DuplicateIcon, SelectorIcon, CheckIcon } from '@heroicons/vue/solid';
import AppButton from '@/components/Common/AppButton.vue';
import ReportProgress from '@/components/App/ReportProgress.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { Project, TimeRecordInput, Month, SaveTimeRecordsDocument, GetTimeRecordsDocument, Time, GetPersonProjectsDocument, Contract } from '@/graphql/generated-operations';
import { format, endOfMonth } from 'date-fns';
import { useToast } from 'vue-toastification';
import { processResponseErrors } from '@/helpers/errors';

const SELECT_PROJECT_PROMPT_ID = '-1';

@Options({
  components: {
    Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, ComboboxButton, TransitionRoot,
    ReportProgress,
    AppButton,
    XIcon, PlusIcon, DuplicateIcon, SelectorIcon, CheckIcon,
  }
})
export default class ReportTable extends Vue {

  @Prop()
  contract!: Contract;

  @Prop()
  dataMode!: 'month' | 'date';

  @Prop()
  month!: Month;

  @Prop()
  date!: string;

  projectsLoaded = false;
  dataLoaded = false;

  projects: Project[] = [];

  records: TimeRecordInput[] = [];

  dateFrom!: string;
  dateTo!: string;

  reportedTime!: Time;
  allocatedTime!: Time;

  queryProject = '';
  queryTask = '';

  created() {
    if ( this.dataMode === 'month' ) {
      const dateFrom = new Date(this.month.key + '-01');
      this.dateFrom = format(dateFrom, 'y-MM-dd');
      this.dateTo = format(endOfMonth(dateFrom), 'y-MM-dd');
    }
    else {
      this.dateFrom = this.date;
      this.dateTo = this.date;
    }

    this.fetchProjects();
    this.fetchData();
  }

  fetchProjects() {
    const { onResult } = useQuery(GetPersonProjectsDocument, {
      date: this.dataMode == 'date' ? this.date : `${this.month.key}-01`,
      personID: this.contract.person.id,
    });

    onResult(queryResult => {
      this.projects = queryResult.data.getPersonProjects;

      const promptOption = {id: SELECT_PROJECT_PROMPT_ID, jiraKey: '', name: '-- Select project --', tasks: []};
      this.projects = [promptOption, ...this.projects];

      this.projectsLoaded = true;
    });
  }

  fetchData() {
    const { onResult: onDataResult } = useQuery(GetTimeRecordsDocument, {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      contractID: this.contract.id
    });

    onDataResult(queryResult => {
      this.allocatedTime = queryResult.data.getContractAllocatedTime;
      this.reportedTime = queryResult.data.getContractReportedTime;

      const data = queryResult.data.getTimeRecords;
      this.records = data.map((record: any) => {
        return {
          date: String((new Date(record.date)).getDate()),
          hours: Math.floor(record.minutes / 60),
          minutes: record.minutes % 60,
          project: record.project.id,
          task: record.task,
          comment: record.comment
        };
      });

      this.dataLoaded = true;
    });
  }

  addRecords(count: number) {
    for ( let i = 0; i < count; i++ ) {
      let date = '1';
      if ( this.dataMode === 'date' ) {
        date = format(new Date(this.date), 'dd');
      }

      this.records.push({
        date: date,
        project: SELECT_PROJECT_PROMPT_ID,
      });
    }
  }

  duplicateRecord(key: number) {
    this.records = this.records.flatMap((record, index) => index == key ? [record, {...record}] : record);
  }

  removeRecord(key: number) {
    this.records.splice(key, 1);
  }

  isPromptOption(option: string): boolean {
    return option == SELECT_PROJECT_PROMPT_ID;
  }

  getProjectLabel(projectID: string) {
    const project = this.projects.find(project => project.id == projectID);

    if ( !project ) return '';

    if ( this.isPromptOption(projectID) ) {
      return project.name;
    }
    else {
      return `${project.id}: ${project.name}`;
    }
  }

  validateData() {
    for ( const record of this.records ) {
      if ( !record.date ) {
        useToast().error('Please enter date for all records.');
        return false;
      }

      const project = this.projects.find(p => p.id == record.project);
      if ( project == undefined || record.project === SELECT_PROJECT_PROMPT_ID ) {
        useToast().error('Please select project for all records.');
        return false;
      }
      else if ( project.taskRequired && !record.task ) {
        useToast().error(`Please select task for project ${record.project}`);
        return false;
      }
      else if ( project.taskRequired ) {
        const projectTask = project.tasks?.find(projectTask => projectTask.id === record.task);
        if ( !projectTask ) {
          useToast().error(`Please select valid task for project ${record.project}`);
          return false;
        }
      }
    }

    return true;
  }

  parseNumber(number: unknown) : number | undefined {
    return Number.isInteger(number) ? Number(number) : undefined;
  }

  async submitData(): Promise<boolean> {
    if ( !this.validateData() ) {
      return false;
    }

    const { mutate: saveMonthTimeRecords } = useMutation(SaveTimeRecordsDocument);
    const result = await saveMonthTimeRecords({
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      contractID: this.contract.id,
      records: this.records.map(record => {
        return {
          ...record,
          hours: this.parseNumber(record.hours),
          minutes: this.parseNumber(record.minutes),
        }
      }),
    });

    const isSuccess = processResponseErrors(result?.data?.saveTimeRecords);

    return isSuccess;
  }

  get filteredProjects()
  {
    if ( this.queryProject === '' ) {
      return this.projects;
    }

    return this.projects.filter(project => {
      if ( this.isPromptOption(project.id) ) return false;

      return this.getProjectLabel(project.id)
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(this.queryProject.toLowerCase().replace(/\s+/g, ''));
    });
  }

  getProjectTasks(projectID: string)
  {
    const project = this.projects.find(project => project.id == projectID);

    return project ? project.tasks : [];
  }

  hasRequiredTask(projectID: string)
  {
    const project = this.projects.find(project => project.id == projectID);

    return project && project.taskRequired;
  }

  getTaskDisplayValue(projectID: string, task: string)
  {
    const project = this.projects.find(project => project.id == projectID);

    if ( !project ) return task;

    const projectTask = project.tasks?.find(t => t.id == task);
    if ( projectTask ) {
      return `${projectTask.id} - ${projectTask.name}`;
    }

    return task;
  }


}
