import { createApp, h, provide } from 'vue';
import App from './App.vue';
import router from './router';

import './index.css';

import { apolloClient } from './graphql/apolloClient';
import { provideApolloClient } from '@vue/apollo-composable';

import Toast, { PluginOptions, TYPE } from 'vue-toastification';
import "vue-toastification/dist/index.css";

import 'v-calendar/dist/style.css';

const toastOptions: PluginOptions = {
  timeout: 3000,
  hideProgressBar: true,
  showCloseButtonOnHover: true,
  toastDefaults: {
    [TYPE.ERROR]: {
      timeout: 5000,
    }
  }
};


createApp({
  setup() {
    provideApolloClient(apolloClient)
  },

  render: () => h(App),
})
  .use(router)
  .use(Toast, toastOptions)
  .mount('#app');
