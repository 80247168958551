
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PencilIcon, XIcon } from '@heroicons/vue/solid';
import AppButton from '@/components/Common/AppButton.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { GetAbsencesDocument, Absence, DeleteAbsenceDocument } from '@/graphql/generated-operations';
import router from '@/router';
import { format } from 'date-fns';
import { useToast } from 'vue-toastification';
import { processResponseErrors } from '@/helpers/errors';
import { formatTime } from '@/helpers/formatTime';

@Options({
  components: {
    AppButton,
    PencilIcon, XIcon
  }
})
export default class AbsenceTable extends Vue {

  @Prop()
  contractID!: string;

  @Prop()
  includeNameColumn = false;

  @Prop()
  openAbsences = false;

  loading = true;

  absences: Absence[] = [];

  created() {
    let where: any = {}
    if ( this.contractID ) {
      where.contractID = this.contractID;
    }
    if ( this.openAbsences ) {
      where.open = true;
    }

    const { onResult: onDataResult } = useQuery(GetAbsencesDocument, where);

    onDataResult(queryResult => {
      this.absences = queryResult.data.getAbsences;

      this.loading = false;
    });
  }

  getAbsenceHours(absence: Absence)
  {
    const days = [...absence.days]

    days.sort((a, b) => {
      if ( a.date < b.date ) {
        return -1
      }
      else if ( a.date > b.date ) {
        return 1
      }

      return 0
    })

    const firstDay = days[0]
    const lastDay = days[days.length - 1]

    const formatMinutes = (minutes: number | undefined) => {
      return minutes ? formatTime({hours: Math.floor(minutes / 60), minutes: minutes % 60}) : 'F'
    }

    const absenceHours: any = {
      firstDay: formatMinutes(firstDay.minutes ?? undefined),
      lastDay: undefined
    }

    if ( firstDay.date != lastDay.date ) {
      absenceHours.lastDay = formatMinutes(lastDay.minutes ?? undefined)
    }

    return absenceHours
  }

  formatDate(date: Date)
  {
    return format(new Date(date), 'd. M. Y');
  }

  OnClickEditAbsence(absence: Absence)
  {
    router.push({name: 'Absence', params: {absenceID: absence.id, contractID: absence.contract!.id}});
  }

  async OnClickDeleteAbsence(absenceID: string)
  {
    if ( !confirm('Delete this absence?') ) {
      return;
    }

    const { mutate: deleteAbsence } = useMutation(DeleteAbsenceDocument);
    const result = await deleteAbsence({
      absenceID: absenceID
    });

    const isSuccess = processResponseErrors(result?.data?.deleteAbsence);

    if ( isSuccess ) {
      this.absences = this.absences.filter(absence => absence.id != absenceID);
      useToast().success('Absence has been deleted');
    }
  }

}
