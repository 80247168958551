import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import PublicLayout from '../views/Public/PublicLayout.vue';
import Login from '../views/Public/Login.vue';

import AppLayout from '../views/App/AppLayout.vue';
import Report from '../views/App/Reports.vue';
import MyReport from '../views/App/MyReport.vue';
import Overview from '../views/App/Overview.vue';
import Absences from '../views/App/Absences.vue';
import AbsencesCalendar from '../views/App/AbsencesCalendar.vue';
import MyAbsences from '../views/App/MyAbsences.vue';
import Absence from '../views/App/Absence.vue';
import Schedule from '../views/App/Schedule.vue';
import Projects from '../views/App/Projects.vue';
import Locks from '../views/App/Locks.vue';
import Lock from '../views/App/Lock.vue';
import ExportProjTool from '../views/App/ExportProjTool.vue';
import ExportIco from '../views/App/ExportIco.vue';
import ExportAbraWork from '../views/App/ExportAbraWork.vue';
import ExportVch from '../views/App/ExportVch.vue';
import { AuthToken } from '@/auth/AuthToken';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: PublicLayout,
    redirect: '/app',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login
      }
    ]
  },
  {
    path: '/app',
    name: 'App',
    component: AppLayout,
    beforeEnter: (to, from, next) => {
      if (!AuthToken.hasAuthToken()) next({ name: 'Login' });
      else next();
    },
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'my-report',
        name: 'My Report',
        component: MyReport
      },
      {
        path: 'projects',
        name: 'Projects',
        component: Projects
      },
      {
        path: 'absences/:open?',
        name: 'Absences',
        component: Absences
      },
      {
        path: 'my-absences',
        name: 'My Absences',
        component: MyAbsences
      },
      {
        path: 'absences-calendar',
        name: 'AbsencesCalendar',
        component: AbsencesCalendar
      },
      {
        path: 'absence/:contractID/:absenceID?',
        name: 'Absence',
        component: Absence
      },
      {
        path: 'schedule',
        name: 'Schedule',
        component: Schedule
      },
      {
        path: 'reports',
        name: 'Reports',
        component: Report
      },
      {
        path: 'locks',
        name: 'Locks',
        component: Locks
      },
      {
        path: 'lock',
        name: 'Lock',
        component: Lock
      },
      {
        path: 'exports/projtool',
        name: 'ExportProjTool',
        component: ExportProjTool
      },
      {
        path: 'exports/ico',
        name: 'ExportIco',
        component: ExportIco
      },
      {
        path: 'exports/abrawork',
        name: 'ExportAbraWork',
        component: ExportAbraWork
      },
      {
        path: 'exports/vch',
        name: 'ExportVch',
        component: ExportVch
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
