
import { Options, Vue } from 'vue-class-component';
import { TruckIcon, CalendarIcon, LockClosedIcon, CheckIcon, RefreshIcon } from '@heroicons/vue/solid';
import AppButton from '@/components/Common/AppButton.vue';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import ExportAbraWorkTable from '@/components/App/ExportAbraWorkTable.vue';
import SystemLockModal from '@/components/App/SystemLockModal.vue';

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { useToast } from 'vue-toastification';
import { DatePicker } from 'v-calendar';
import { useApolloClient, useQuery, useMutation } from '@vue/apollo-composable';
import { GetAbraWorkReportCsvDocument, FetchAbraWorkJsonReportDocument, SyncAbraWorkReportDocument, GetMonthsDocument, Month, SystemLockReason } from '@/graphql/generated-operations';
import FileSaver from 'file-saver';

@Options({
  components: {
    AppButton,
    TruckIcon, CalendarIcon, LockClosedIcon, CheckIcon, RefreshIcon,
    ExportAbraWorkTable,
    DatePicker,
    SystemLockModal,
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
    TabGroup, TabList, Tab, TabPanels, TabPanel,
  },
})
export default class ExportAbraWork extends Vue {

  months: Month[] = [];
  selectedMonth: Month | null = null;

  SystemLockReason: any = SystemLockReason;

  loading = true;

  syncModalIsOpen = false;
  syncModalContent: 'existingRecords' | 'skippedContracts' = 'existingRecords';
  skippedGaliaContracts: any[] = [];
  skippedAbraContracts: any[] = [];

  syncInProgress = false;
  fetchInProgress = false;

  created() {
    const { onResult } = useQuery(GetMonthsDocument);

    onResult(queryResult => {
      this.months = queryResult.data.getMonths;

      const currentMonth = format(new Date(), 'y-MM');
      this.months.map((month, index) => {
        if ( month.key == currentMonth ) {
          this.selectedMonth = this.months[index];
        }
      });

      this.loading = false;
    });
  }

  get dateFrom() {
    return startOfMonth(new Date(`${this.selectedMonth!.key}-01`));
  }

  get dateTo() {
    return endOfMonth(new Date(`${this.selectedMonth!.key}-01`));
  }

  formatDate(date: Date) {
    return format(date, 'y-MM-dd');
  }

  parseAbraDate(abraDate: string) {
    if ( !abraDate ) return ''

    const [datePart, timePart] = abraDate.split('+')

    const date = new Date(datePart)
    return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`
  }

  lockState() {
    const systemLockModal = this.$refs.systemLockModal as SystemLockModal;
    systemLockModal.openModal();
  }

  closeSyncModal() {
    this.syncModalIsOpen = false;
  }

  fetchAbraWorkReport() {
    this.fetchInProgress = true;

    const { onResult } = useQuery(FetchAbraWorkJsonReportDocument, {
      month: this.selectedMonth!.key
    });

    onResult(queryResult => {
      const name = `${this.selectedMonth!.key}_abra_work_report_${format(new Date(), 'y-MM-dd-HH-mm-ss')}.json`;
      const content = queryResult.data?.fetchAbraWorkJsonReport;

      const blob = new Blob([content], {type: 'application/json'});
      FileSaver.saveAs(blob, name);

      this.fetchInProgress = false;
    });
  }

  async syncWithAbra(skipExistingRecordsCheck = false) {
    this.syncInProgress = true;

    const { mutate: syncAbraWorkReport } = useMutation(SyncAbraWorkReportDocument);
    const result = await syncAbraWorkReport({
      month: this.selectedMonth!.key,
      skipExistingRecordsCheck: skipExistingRecordsCheck,
    });

    this.syncInProgress = false;

    const data = result?.data?.syncAbraWorkReport;

    if ( data ) {
      if ( data.finished === true ) {
        if ( (data.skippedGaliaContracts && data.skippedGaliaContracts.length > 0) ||
             (data.skippedAbraContracts && data.skippedAbraContracts.length > 0)
        ) {
          this.skippedGaliaContracts = data.skippedGaliaContracts ?? [];
          this.skippedAbraContracts = data.skippedAbraContracts ?? [];
          this.syncModalContent = 'skippedContracts';
          this.syncModalIsOpen = true;
        }
        else {
          this.syncModalIsOpen = false;
          useToast().success('Sync Finished');
          this.lockState();
        }
      }
      else {
        if ( data.existingAbraRecords ) {
          this.syncModalContent = 'existingRecords';
          this.syncModalIsOpen = true;
        }
        else {
          useToast().error(data.error ?? 'Internal Error');
        }
      }
    }
  }

  async exportCsv() {
    const query = await useApolloClient().client.query({
      query: GetAbraWorkReportCsvDocument,
      variables: {
        month: this.selectedMonth!.key,
      }
    });

    const {name, content} = query.data.getAbraWorkReportCsv;

    const blob = new Blob([content], {type: 'text/csv'});
    FileSaver.saveAs(blob, name);

    const systemLockModal = this.$refs.systemLockModal as SystemLockModal;
    systemLockModal.openModal(false);
  }

}
