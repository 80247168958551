
import { Options, Vue } from 'vue-class-component';

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import AppButton from '@/components/Common/AppButton.vue';
import LockTable from '@/components/App/LockTable.vue';
import { PlusIcon } from '@heroicons/vue/solid';
import router from '@/router';
import { Lock } from '@/graphql/generated-operations';
import { format } from 'date-fns';

@Options({
  components: {
    LockTable,
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
    AppButton,
    PlusIcon,
  },
})
export default class Locks extends Vue {

  modalIsOpen = false;

  modalLock: Lock | null = null;

  onNewLockClick()
  {
    router.push({name: 'Lock'});
  }

  formatDate(date: string)
  {
    return format(new Date(date), 'd.M.Y');
  }

  formatDatetime(date: number)
  {
    return format(new Date(Number(date)), 'd. M. Y H:mm:ss');
  }

  closeModal() {
    this.modalIsOpen = false;
  }

  openViolationModal(lock: Lock) {
    this.modalLock = lock;

    this.modalIsOpen = true;
  }
}
