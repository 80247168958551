
import { Options, Vue } from 'vue-class-component';
import router from "@/router";

import AppButton from '@/components/Common/AppButton.vue';
import AbsenceTable from '@/components/App/AbsenceTable.vue';
import { PlusIcon } from '@heroicons/vue/solid';
import { Person } from '@/graphql/generated-operations';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
    AbsenceTable,
    AppButton,
    PlusIcon,
  },
})
export default class Absences extends Vue {

  @Prop({default: null})
  user!: Person;

  openAbsences = false;

  created() {
    this.openAbsences = this.$route.params.open as unknown as boolean;
  }

  onAllAbsencesClick()
  {
    router.push({name: 'Absences', params: {open: null}});
    this.openAbsences = false;
  }

  onOpenAbsencesClick()
  {
    router.push({name: 'Absences', params: {open: 'open'}});
    this.openAbsences = true;
  }

}
