
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PlusIcon, CheckCircleIcon } from '@heroicons/vue/solid';
import AppButton from '@/components/Common/AppButton.vue';
import { useQuery } from '@vue/apollo-composable';
import { GetVchReportRecordDocument, VchReportRecord } from '@/graphql/generated-operations';

@Options({
  components: {
    AppButton,
    PlusIcon, CheckCircleIcon
  }
})
export default class ExportVchRecord extends Vue {

  @Prop()
  recordID!: string;

  loading = true;

  vchReportRecord: VchReportRecord | null = null;

  created() {
    const { onResult: onDataResult } = useQuery(GetVchReportRecordDocument, {
      recordID: this.recordID,
    });

    onDataResult(queryResult => {
      if ( queryResult.data.getVchReportRecord ) {
        this.vchReportRecord = queryResult.data.getVchReportRecord;
      }

      this.loading = false;
    });
  }

}
