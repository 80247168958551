import * as msal from '@azure/msal-browser'
import { msalConfig, loginRequest } from './authConfig'
import { callMSGraph } from './graph'

// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
const myMSALObj = new msal.PublicClientApplication(msalConfig)

let username = ""

/**
 * A promise handler needs to be registered for handling the
 * response returned from redirect flow. For more information, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 */
myMSALObj.handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.error(error)
  })

export async function selectAccount () {

  /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

  const currentAccounts = await myMSALObj.getAllAccounts()

  if (currentAccounts.length === 0) {
    return
  } else if (currentAccounts.length > 1) {
    // Add your account choosing logic here
    // console.warn("Multiple accounts detected.")
  } else if (currentAccounts.length === 1) {
    username = currentAccounts[0].username

    // TODO log
    // console.log('--- MS user ---', username)
    return currentAccounts[0]
  }
}

export async function handleResponse(response) {

  if (response !== null) {
    username = response.account.username
    return response
  } else {
    return selectAccount()
  }
}

export async function signIn() {
  return myMSALObj.loginPopup(loginRequest)
    .then(handleResponse)
    .catch(error => {
      console.error(error)
    })
}

export function signOut() {

  /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

  const logoutRequest = {
    account: myMSALObj.getAccountByUsername(username),
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
  }

  return myMSALObj.logoutPopup(logoutRequest)
}

export function getTokenRedirect(request) {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  request.account = myMSALObj.getAccountByUsername(username)

  return myMSALObj.acquireTokenSilent(request)
    .catch(error => {
      console.warn("silent token acquisition fails. acquiring token using redirect")
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return myMSALObj.acquireTokenRedirect(request)
      } else {
        console.warn(error)
        throw error
      }
    })
}

export async function getTokenPopup(request) {

  /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
  request.account = myMSALObj.getAccountByUsername(username)

  return myMSALObj.acquireTokenSilent(request)
    .catch(error => {
      console.warn("silent token acquisition fails. acquiring token using popup")
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return myMSALObj.acquireTokenPopup(request)
          .then(tokenResponse => {
            // console.log(tokenResponse)
            return tokenResponse
          }).catch(error => {
            console.error(error)
          })
      } else {
        console.warn(error)
      }
    })
}

export async function seeProfile() {
  try {
    const response = await getTokenPopup(loginRequest)

    // console.log(response)

    const profile = await callMSGraph('https://graph.microsoft.com/v1.0/me', response.accessToken)
    // TODO log
    // console.log('--profile--', profile)

    return profile
  } catch (err) {
    console.error(err)
    throw err
  }
}
