
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { Prop } from 'vue-property-decorator';
import { CheckIcon, TruckIcon, UploadIcon, PlusIcon } from '@heroicons/vue/solid';
import ReportTable from '@/components/App/ReportTable.vue';
import AppButton from '@/components/Common/AppButton.vue';
import { useApolloClient, useMutation, useQuery } from '@vue/apollo-composable';
import { Contract, GetCsvContractTimeRecordsDocument, GetMonthsDocument, GetPersonActiveContractsDocument, ImportCsvContractTimeRecordsDocument, Month, Person } from '@/graphql/generated-operations';
import { format } from 'date-fns';
import { useToast } from 'vue-toastification';
import { processResponseErrors } from '@/helpers/errors';
import FileSaver from 'file-saver';
import FileUpload, { VueUploadItem } from 'vue-upload-component';

@Options({
  components: {
    AppButton,
    ReportTable,
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
    CheckIcon, TruckIcon, UploadIcon, PlusIcon,
    FileUpload,
  },
})
export default class MyReport extends Vue {

  @Prop({default: null})
  user!: Person;

  selectedContract: Contract | null = null;
  selectedMonth: Month | null = null;

  months: Month[] = [];
  contracts: Contract[] = [];
  reportTableRefreshKey = 0;

  loading = true;

  modalIsOpen = false;

  csvImportFile: VueUploadItem | null = null;

  created() {
    const { onResult } = useQuery(GetMonthsDocument, {
      personID: this.user.id
    });

    onResult(queryResult => {
      this.months = queryResult.data.getMonths;

      const currentMonth = format(new Date(), 'y-MM');
      this.months.map((month, index) => {
        if ( month.key == currentMonth) {
          this.selectedMonth = this.months[index];
        }
      });

    });
  }

  @Watch('selectedMonth', {deep: true})
  async onMonthChange()
  {
    this.loading = true;

    const query = await useApolloClient().client.query({
      query: GetPersonActiveContractsDocument,
      variables: {
        month: this.selectedMonth!.key,
        personID: this.user.id,
      }
    });

    this.contracts = query.data.getActiveContracts;

    const contract = this.contracts.find(contract => contract.id == this.selectedContract?.id);
    if ( !contract ) {
      if ( this.contracts.length > 0 ) {
        this.selectedContract = this.contracts[0];
      }
      else {
        this.selectedContract = null;
      }
    }

    this.loading = false;
  }

  async onReportSubmit() {
    const reportTable = this.$refs.reportTable as ReportTable;
    const result = await reportTable.submitData();

    if ( result ) {
      useToast().success('Report has been saved.');
      this.reportTableRefreshKey++;
    }
  }

  async exportToCsv() {
    const query = await useApolloClient().client.query({
      query: GetCsvContractTimeRecordsDocument,
      variables: {
        contractID: this.selectedContract!.id,
        month: this.selectedMonth!.key,
      }
    });

    const {name, content} = query.data.getCsvContractTimeRecords;

    const blob = new Blob([content], {type: 'text/csv'});
    FileSaver.saveAs(blob, name);
  }

  openImportModal() {
    this.modalIsOpen = true;
  }

  closeImportModal() {
    this.modalIsOpen = false;
  }

  onInputFile(inputFile: VueUploadItem | undefined) {
    if ( !inputFile || inputFile.file === undefined ) return;

    this.csvImportFile = inputFile;
  }

  async importCsvFile() {
    if ( !this.csvImportFile || !this.csvImportFile.file ) {
      useToast().error('Please select valid CSV file.');
      return;
    }

    const data = this.csvImportFile.file;

    const { mutate: saveMonthTimeRecords } = useMutation(ImportCsvContractTimeRecordsDocument);
    const result = await saveMonthTimeRecords({
      contractID: this.selectedContract!.id,
      month: this.selectedMonth!.key,
      content: await data.text(),
    });

    const isSuccess = processResponseErrors(result?.data?.importCsvContractTimeRecords);
    if ( !isSuccess ) {
      this.csvImportFile = null;
      return;
    }

    useToast().success('CSV file has been successfully imported.');
    this.reportTableRefreshKey++;
    this.modalIsOpen = false;
    this.csvImportFile = null;
  }

}
