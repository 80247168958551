import { AuthToken } from '@/auth/AuthToken';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, concat } from '@apollo/client/core'


const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${AuthToken.getAuthToken()}`
    }
  });

  return forward(operation);
});


const httpLink = createHttpLink({
  uri: process.env.VUE_APP_API_URL,
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    }
  }
});
