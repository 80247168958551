
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { AuthToken } from "@/auth/AuthToken";
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { LogoutIcon, ChevronDownIcon } from '@heroicons/vue/solid';
import AppButton from '@/components/Common/AppButton.vue';
import { MyProfileDocument, Person, URole } from "@/graphql/generated-operations";
import { useQuery } from "@vue/apollo-composable";

enum MenuView {
  User,
  Admin,
}

@Options({
  components: {
    Menu, MenuButton, MenuItems, MenuItem,
    AppButton,
    LogoutIcon, ChevronDownIcon
  }
})
export default class AppLayout extends Vue {

  user: Person | null = null;

  MenuView = MenuView;
  menuView: MenuView = MenuView.User;

  created() {
    const { onResult, onError } = useQuery(MyProfileDocument);

    onResult(queryResult => {
      if ( queryResult?.data?.myProfile ) {
        this.user = queryResult.data.myProfile;

        if ( router.currentRoute.value.name == 'App' ) {
          router.push({name: 'Overview'});
        }
      }
      else {
        this.logout();
      }
    });

    onError(() => {
      this.logout();
    });
  }

  get router() {
    return router;
  }

  get isAdmin(): boolean {
    return this.user?.role == URole.Admin;
  }

  logout() {
    AuthToken.clearAuthToken();
    router.push({name: 'Login'});
  }

  onSwitchMenuClick(menuView: MenuView)
  {
    this.menuView = menuView;
  }

  onLogoutClick() {
    this.logout();
  }

}
