
import { Options, Vue } from 'vue-class-component';
import { TruckIcon, CalendarIcon, LockClosedIcon } from '@heroicons/vue/solid';
import AppButton from '@/components/Common/AppButton.vue';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import ExportIcoTable from '@/components/App/ExportIcoTable.vue';
import SystemLockModal from '@/components/App/SystemLockModal.vue';

import { DatePicker } from 'v-calendar';
import { useApolloClient } from '@vue/apollo-composable';
import { GetIcoReportCsvDocument, SystemLockReason } from '@/graphql/generated-operations';
import FileSaver from 'file-saver';

@Options({
  components: {
    AppButton,
    TruckIcon, CalendarIcon, LockClosedIcon,
    ExportIcoTable,
    DatePicker,
    SystemLockModal,
  },
})
export default class ExportProjTool extends Vue {

  date: {
    start: Date | null,
    end: Date | null,
  } = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };

  SystemLockReason: any = SystemLockReason;

  formatDate(date: Date) {
    return format(date, 'y-MM-dd');
  }

  lockState() {
    const systemLockModal = this.$refs.systemLockModal as SystemLockModal;
    systemLockModal.openModal();
  }

  async exportCsv() {
    const query = await useApolloClient().client.query({
      query: GetIcoReportCsvDocument,
      variables: {
        dateFrom: this.formatDate(this.date.start!),
        dateTo: this.formatDate(this.date.end!),
      }
    });

    const {name, content} = query.data.getIcoReportCsv;

    const blob = new Blob([content], {type: 'text/csv'});
    FileSaver.saveAs(blob, name);

    const systemLockModal = this.$refs.systemLockModal as SystemLockModal;
    systemLockModal.openModal(false);
  }

}
