
import { Options, Vue } from 'vue-class-component';
import router from "@/router";

import AppButton from '@/components/Common/AppButton.vue';
import AbsenceTable from '@/components/App/AbsenceTable.vue';
import { PlusIcon } from '@heroicons/vue/solid';
import { useQuery } from '@vue/apollo-composable';
import { Contract, GetPersonContractsDocument, Person } from '@/graphql/generated-operations';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
    AbsenceTable,
    AppButton,
    PlusIcon,
  },
})
export default class MyAbsences extends Vue {

  @Prop({default: null})
  user!: Person;

  contracts: Contract[] = [];
  selectedContract: Contract | null = null;

  loading = true;

  created() {
    const { onResult } = useQuery(GetPersonContractsDocument, {
      personID: this.user.id
    });

    onResult(queryResult => {
      this.contracts = queryResult.data.getContractsByPerson;

      if ( this.contracts.length > 0 ) {
        this.selectedContract = this.contracts[0];
      }
      this.loading = false;
    });
  }

  onNewAbsenceClick()
  {
    router.push({name: 'Absence', params: {contractID: this.selectedContract!.id}});
  }

}
