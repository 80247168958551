import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    ref: "button",
    class: _normalizeClass(
    [
      'inline-flex items-center justify-center',
      _ctx.padding == 'small' ? 'px-2 py-2' : 'px-4 py-2',
      'rounded-md text-center text-sm font-medium leading-5 tracking-wide',
      _ctx.type == 'blue' && 'text-white bg-gradient-to-br from-blue-500 to-indigo-700 hover:from-blue-600 hover:to-indigo-700',
      _ctx.type == 'blue-disabled' && 'text-white bg-gradient-to-br from-blue-200 to-indigo-300 cursor-not-allowed',
      _ctx.type == 'green' && 'text-white bg-gradient-to-br from-green-500 to-lime-600 hover:from-green-600 hover:to-lime-700',
      _ctx.type == 'green-disabled' && 'text-white bg-gradient-to-br from-green-100 to-lime-200 cursor-not-allowed',
      _ctx.type == 'red' && 'text-white bg-gradient-to-br from-red-500 to-pink-700 hover:from-red-600 hover:to-pink-700',
      _ctx.type == 'gray' && 'bg-white hover:bg-gray-50 text-gray-700 border border-gray-300',
    ])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}