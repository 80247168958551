
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { useMutation, useQuery } from "@vue/apollo-composable";
import { TemplateIcon, CheckIcon, XIcon } from '@heroicons/vue/solid';
import { Month, GetMonthScheduleDocument, Contract, CalendarDay, Time, SaveScheduleDocument, ScheduleMonth, ScheduleMonthInput } from '@/graphql/generated-operations';

import { formatTime } from '@/helpers/formatTime';
import AppButton from '@/components/Common/AppButton.vue';
import { useToast } from "vue-toastification";
import { processResponseErrors } from "@/helpers/errors";

interface ContractSchedule {
  type: string,
  sum: Time,
  days: Map<string, Time>,
}

@Options({
  components: {
    AppButton,
    TemplateIcon, CheckIcon, XIcon
  }
})
export default class ScheduleTable extends Vue {

  @Prop()
  month!: Month;

  contracts: Contract[] = [];
  days: CalendarDay[] = [];

  schedule = new Map<string, ContractSchedule>();
  scheduleTemplate = new Map<string, ContractSchedule>();

  activeTimeDialogue = {
    contractID: '',
    date: '',
    hours: 0,
    minutes: 0,
  };

  loading = true;

  created() {
    const { onResult } = useQuery(GetMonthScheduleDocument, {
      month: this.month.key,
    });

    onResult(queryResult => {
      this.contracts = queryResult.data.getActiveContracts;
      this.days = queryResult.data.getMonthDays;

      for ( const scheduleTemplateRecord of queryResult.data.getScheduleFromTemplates ) {
        const contractSchedule: ContractSchedule = {
          type: scheduleTemplateRecord.type,
          sum: {
            hours: scheduleTemplateRecord.sum ? Math.floor(scheduleTemplateRecord.sum / 60) : 0,
            minutes: scheduleTemplateRecord.sum ? scheduleTemplateRecord.sum % 60 : 0,
          },
          days: new Map<string, Time>(),
        }

        for ( const day of scheduleTemplateRecord.days ) {
          contractSchedule.days.set(day.date, {
            hours: Math.floor(day.minutes / 60),
            minutes: day.minutes % 60,
          });
        }

        this.scheduleTemplate.set(scheduleTemplateRecord.contractID, contractSchedule);
        this.schedule.set(scheduleTemplateRecord.contractID, {
          ...contractSchedule,
          sum: {
            hours: 0,
            minutes: 0,
          },
          days: new Map<string, Time>(),
        })
      }

      for ( const scheduleRecord of queryResult.data.getMonthSchedule ) {
        const contractSchedule = this.schedule.get(scheduleRecord.contractID);

        if ( !contractSchedule ) continue;

        if ( scheduleRecord.sum ) {
          contractSchedule.sum.hours = Math.floor(scheduleRecord.sum / 60);
          contractSchedule.sum.minutes = scheduleRecord.sum % 60;
        }

        for ( const day of scheduleRecord.days ) {
          contractSchedule.days.set(day.date, {
            hours: Math.floor(day.minutes / 60),
            minutes: day.minutes % 60,
          });
        }
      }

      this.loading = false;
    });
  }

  isContractOfDaysType(contractID: string) {
    return this.scheduleTemplate.get(contractID)?.type == 'days';
  }

  formatTime(time: Time) {
    return formatTime(time);
  }

  getTime(contractID: string, date: string) {
    const time = this.schedule.get(contractID)?.days.get(date);

    return time ? formatTime(time) : '0h';
  }

  getTimeMinutes(contractID: string, date: string) {
    const time = this.schedule.get(contractID)?.days.get(date);

    return time ? time.hours * 60 + time.minutes : 0;
  }

  getContractTimeSum(contractID: string): Time {
    const contractSchedule = this.schedule.get(contractID);

    const time = {
      hours: 0,
      minutes: 0,
    }

    if ( contractSchedule ) {
      contractSchedule.days.forEach((date) => {
        time.hours += date.hours;
        time.minutes += date.minutes;
      });

      time.hours += Math.floor(time.minutes / 60);
      time.minutes = time.minutes % 60;
    }

    return time;
  }

  setScheduleTime(contractID: string, date: string, time: Time) {
    if ( !this.schedule.has(contractID ) ) {
      const template = this.scheduleTemplate.get(contractID);

      if ( template ) {
        this.schedule.set(contractID, {
          ...template,
          days: new Map<string, Time>(),
        });
      }
    }

    time.hours = time.hours + Math.floor(time.minutes / 60);
    time.minutes = time.minutes % 60;

    this.schedule.get(contractID)?.days.set(date, time);
  }

  onOpenTimeDialogue(contractID: string, date: string) {
    this.activeTimeDialogue.contractID = contractID;
    this.activeTimeDialogue.date = date;

    const time = this.schedule.get(contractID)?.days.get(date);

    if ( time ) {
      this.activeTimeDialogue.hours = time.hours;
      this.activeTimeDialogue.minutes = time.minutes;
    }
    else {
      this.activeTimeDialogue.hours = 0;
      this.activeTimeDialogue.minutes = 0;
    }
  }

  onClickSetAllContractsTemplateTime() {
    for ( const contract of this.contracts ) {
      this.onClickSetContractTemplateTime(contract.id);
    }
  }

  onClickSetContractTemplateTime(contractID: string) {
    const template = this.scheduleTemplate.get(contractID);

    if ( template ) {
      this.schedule.set(contractID, {
        ...template,
        sum: {
          ...template.sum,
        },
        days: new Map<string, Time>(template.days),
      });
    }
  }

  onClickSetTemplateTime(contractID: string, date: string) {
    const time = this.scheduleTemplate.get(contractID)?.days.get(date);

    this.setScheduleTime(contractID, date, {
      hours: time?.hours ?? 0,
      minutes: time?.minutes ?? 0,
    });

    this.closeTimeDialogue();
  }

  onClickSetTime(contractID: string, date: string) {
    this.setScheduleTime(contractID, date, {
      hours: isNaN(this.activeTimeDialogue.hours) ? 0 : this.activeTimeDialogue.hours,
      minutes: isNaN(this.activeTimeDialogue.minutes) ? 0 : this.activeTimeDialogue.minutes,
    });

    this.closeTimeDialogue();
  }

  onClickRemoveTime(contractID: string, date: string) {
    this.setScheduleTime(contractID, date, {
      hours: 0,
      minutes: 0,
    })

    this.closeTimeDialogue();
  }

  closeTimeDialogue() {
    this.activeTimeDialogue.contractID = '';
    this.activeTimeDialogue.date = '';
  }

  getDateBgClass(day: CalendarDay) {
    if ( day.redday ) {
      return 'bg-red-100';
    }
    else if ( day.holiday ) {
      return 'bg-blue-100';
    }
    else if ( day.weekend ) {
      return 'bg-gray-100';
    }
    return '';
  }

  parseNumber(number: unknown) : number | undefined {
    return Number.isInteger(number) ? Number(number) : undefined;
  }

  async onClickSave() {
    const records: ScheduleMonthInput[] = [];

    this.schedule.forEach((contractSchedule, contractID) => {

      let record: ScheduleMonthInput = {
        contractID: contractID,
        type: contractSchedule.type,
        sum: 0,
        days: [],
      };

      if ( contractSchedule.type == 'days' ) {
        contractSchedule.days.forEach((contractDate, date) => {
          record.days.push({
            date: date,
            hours: contractDate.hours,
            minutes: contractDate.minutes,
          });
        });
      }
      else {
        const hours = this.parseNumber(contractSchedule.sum.hours) ?? 0;
        const minutes = this.parseNumber(contractSchedule.sum.minutes) ?? 0;
        record.sum = hours * 60 + minutes;
      }

      records.push(record);
    });

    const { mutate: saveSchedule } = useMutation(SaveScheduleDocument);
    const result = await saveSchedule({
      month: this.month.key,
      records: records,
    });

    const isSuccess = processResponseErrors(result?.data?.saveSchedule);

    if ( isSuccess ) {
      useToast().success('Schedule has been saved.');
    }

  }

}
