
import { Options, Vue } from 'vue-class-component';

import { ClipboardListIcon, XIcon, ShieldCheckIcon } from '@heroicons/vue/solid';
import AppButton from '@/components/Common/AppButton.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { DeleteLockDocument, Lock, GetLocksDocument, AcceptLockViolationsDocument } from '@/graphql/generated-operations';
import { format } from 'date-fns';
import { useToast } from 'vue-toastification';

@Options({
  components: {
    AppButton,
    ClipboardListIcon, XIcon, ShieldCheckIcon
  }
})
export default class LockTable extends Vue {

  loading = true;

  locks: Lock[] = [];

  created() {
    const { onResult: onDataResult } = useQuery(GetLocksDocument);

    onDataResult(queryResult => {
      this.locks = queryResult.data.getLocks;

      this.loading = false;
    });
  }

  isBroken(lock: Lock)
  {
    return lock.violations.length > 0;
  }

  formatDate(date: Date)
  {
    return format(new Date(date), 'd. M. Y');
  }

  async OnClickDeleteLock(lockID: string)
  {
    if ( !confirm('Delete this lock?') ) {
      return;
    }

    const { mutate: deleteLock } = useMutation(DeleteLockDocument);
    const result = await deleteLock({
      lockID: lockID
    });

    if ( result?.data?.deleteLock ) {
      this.locks = this.locks.filter(lock => lock.id != lockID);

      useToast().success('Lock has been deleted');
    }
  }

  async OnClickAcceptLockViolations(lockID: string)
  {
    if ( !confirm('Accept lock violations?') ) {
      return;
    }

    const { mutate: deleteLock } = useMutation(AcceptLockViolationsDocument);
    const result = await deleteLock({
      lockID: lockID
    });

    if ( result?.data?.acceptLockViolations ) {
      this.locks = this.locks.map(lock => {
        if ( lock.id == lockID ) {
          lock = {
            ...lock,
            violations: [],
          };
        }

        return lock;
      })

      useToast().success('Lock violations have been accepted');
    }
  }

}
