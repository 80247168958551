import { Time } from "@/graphql/generated-operations";

export const formatTime = (time: Time) => {
  let result = '';
  if (time.hours > 0 || time.hours == 0 && time.minutes == 0) {
    result += `${time.hours}h`;

    if (time.minutes > 0) {
      result += ':';
    }
  }
  if (time.minutes > 0) {
    result += `${time.minutes}m`;
  }

  return result;
}
