
import { Options, Vue } from 'vue-class-component';

import AppButton from '@/components/Common/AppButton.vue';
import { CheckIcon, CalendarIcon } from '@heroicons/vue/solid';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useToast } from 'vue-toastification';
import { GetLockFormDataDocument, LockReason, Role, SaveLockDocument } from '@/graphql/generated-operations';

import { DatePicker } from 'v-calendar';
import { format } from 'date-fns';
import { processResponseErrors } from '@/helpers/errors';
import router from '@/router';

const SELECT_LOCK_REASON_PROMPT_ID = '-1';

@Options({
  components: {
    AppButton,
    CheckIcon, CalendarIcon,
    DatePicker,
  },
})
export default class Lock extends Vue {

  date: {
    start: Date | null,
    end: Date | null,
  } = {
    start: null,
    end: null,
  };

  selectedLockReason: string = SELECT_LOCK_REASON_PROMPT_ID;
  selectedRoles: string[] = [];

  lockReasons: LockReason[] = [];
  roles: Role[] = [];

  loading = true;

  created() {
    const { onResult } = useQuery(GetLockFormDataDocument);

    onResult(queryResult => {
      this.lockReasons = queryResult.data.getLockReasons;
      this.roles = queryResult.data.getRoles;

      const promptOption = {id: SELECT_LOCK_REASON_PROMPT_ID, name: '-- Select lock reason --'};
      this.lockReasons = [promptOption, ...this.lockReasons];

      this.loading = false;
    });
  }

  formatDate(date: Date)
  {
    return format(date, 'd. M. y');
  }

  async onSubmit()
  {
    if ( !this.date.start || !this.date.end || this.date.start > this.date.end ) {
      useToast().error('Please set valid date range.');
      return;
    }

    if ( this.selectedLockReason == SELECT_LOCK_REASON_PROMPT_ID ) {
      useToast().error('Please select lock reason.');
      return;
    }

    const { mutate: saveLock } = useMutation(SaveLockDocument);
    const result = await saveLock({
      lock: {
        dateFrom: format(this.date.start, 'y-MM-dd'),
        dateTo: format(this.date.end, 'y-MM-dd'),
        lockReason: this.selectedLockReason,
        roles: this.selectedRoles,
      }
    });

    const isSuccess = processResponseErrors(result?.data?.saveLock);

    if ( isSuccess ) {
      useToast().success('Lock has been saved.');
      router.push({name: 'Locks'});
    }
  }

}
