
import { Options, Vue } from 'vue-class-component';
import ContractTable from '@/components/App/ContractTable.vue';
import { format } from 'date-fns';

import { useQuery } from '@vue/apollo-composable';
import { GetMonthsDocument, Month, Person } from '@/graphql/generated-operations';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
    ContractTable,
  },
})
export default class Overview extends Vue {

  @Prop({default: null})
  user!: Person;

  months: Month[] = [];
  selectedMonth: Month | null = null;

  loading = true;

  created() {
    const { onResult: onResult } = useQuery(GetMonthsDocument, {personID: this.user.id});

    onResult(queryResult => {
      this.months = queryResult.data.getMonths;

      const currentMonth = format(new Date(), 'y-MM');
      this.months.map((month, index) => {
        if ( month.key == currentMonth) {
          this.selectedMonth = this.months[index];
        }
      });

      this.loading = false;
    });
  }

}
