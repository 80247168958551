
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Time } from '@/graphql/generated-operations';

import { formatTime } from '@/helpers/formatTime';

export default class ReportProgress extends Vue {

  @Prop()
  reportedTime!: Time;

  @Prop()
  allocatedTime!: Time;

  @Prop()
  labels = false;

  get reportProgress(): number {
    if ( !this.allocatedTime ) {
      return 1;
    }
    else if ( this.timeToMinutes(this.reportedTime) === 0 ) {
      return 0;
    }
    else {
      const progress = this.timeToMinutes(this.reportedTime) / this.timeToMinutes(this.allocatedTime);
      return Math.max(0, progress);
    }
  }

  formatTime(time: Time): string {
    return formatTime(time);
  }

  timeToMinutes(time?: Time): number {
    if ( !time ) return 0;
    return time.hours * 60 + time.minutes;
  }

}
