
import { Options, Vue } from 'vue-class-component';
import { useMutation } from '@vue/apollo-composable';
import router from '@/router';

import { MicrosoftLoginDocument  } from '@/graphql/generated-operations';

import { signIn } from '@/auth/providers/microsoft/authPopup.js';
import { AuthToken } from '@/auth/AuthToken';

import AppButton from '@/components/Common/AppButton.vue';

@Options({
  components: {
    AppButton,
  }
})
export default class Login extends Vue {

  messages: string[] = [];

  async onMicrosoftLoginClick() {
    this.messages = [];

    const msUserObject = await signIn();
    if ( msUserObject ) {
      const { mutate: microsoftLogin, error: microsoftLoginError } = useMutation(MicrosoftLoginDocument);
      const result = await microsoftLogin({token: msUserObject.accessToken});

      if ( microsoftLoginError.value || !result?.data?.microsoftLogin ) {
        this.messages.push('Internal server error. Please contact admin.');
        return;
      }

      const data = result.data.microsoftLogin;
      if ( data.message ) {
        this.messages.push(data.message);
        return;
      }
      else if ( data.token ) {
        AuthToken.setAuthToken(data.token);
        router.push({name: 'App'});
      }

    } else {
      this.messages.push('Problem with obtaining data from Microsoft.');
    }
  }
}
