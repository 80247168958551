
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { useQuery } from "@vue/apollo-composable";
import { CheckIcon, XIcon } from '@heroicons/vue/solid';
import { Month, GetMonthReportDocument, MonthReportContract, DayStatus, MonthReportContractDay, CalendarDay } from '@/graphql/generated-operations';
import ReportProgress from '@/components/App/ReportProgress.vue';

@Options({
  components: {
    ReportProgress,
    CheckIcon, XIcon,
  }
})
export default class ContractTable extends Vue {

  @Prop()
  month!: Month;

  @Prop()
  personID!: string;

  @Prop({default: true})
  activeMode!: boolean;

  @Prop({default: false})
  showMeasureUnit!: boolean;

  days: CalendarDay[] = [];
  contracts: MonthReportContract[] = [];

  loading = true;

  created() {
    const { onResult } = useQuery(GetMonthReportDocument, {
      month: this.month.key,
      personID: this.personID,
    });

    onResult(queryResult => {
      this.days = queryResult.data.getMonthReport.days;
      this.contracts = queryResult.data.getMonthReport.contracts;

      this.loading = false;
    });
  }

  isBlankDay(date: MonthReportContractDay) {
    return date.status == DayStatus.Blank;
  }

  isPartialDay(date: MonthReportContractDay) {
    return date.status == DayStatus.Partial;
  }

  isCompleteDay(date: MonthReportContractDay) {
    return date.status == DayStatus.Complete;
  }

  isEmptyDay(date: MonthReportContractDay) {
    return date.status == DayStatus.Empty;
  }

  getDateBgClass(day: CalendarDay) {
    if ( day.redday ) {
      return 'bg-red-100';
    }
    else if ( day.holiday ) {
      return 'bg-blue-100';
    }
    else if ( day.weekend ) {
      return 'bg-gray-100';
    }
    return '';
  }

}
