
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TruckIcon, CalendarIcon, LockClosedIcon } from '@heroicons/vue/solid';
import AppButton from '@/components/Common/AppButton.vue';
import { format } from 'date-fns';
import ExportVchTable from '@/components/App/ExportVchTable.vue';
import ExportVchRecord from '@/components/App/ExportVchRecord.vue';
import SystemLockModal from '@/components/App/SystemLockModal.vue';

import { CreateVchReportRecordDocument, GetMonthsDocument, Month, Person, SystemLockReason } from '@/graphql/generated-operations';
import { useApolloClient, useQuery } from '@vue/apollo-composable';
import startOfMonth from 'date-fns/startOfMonth';
import { endOfMonth } from 'date-fns/fp';
import { useToast } from 'vue-toastification';

@Options({
  components: {
    AppButton,
    TruckIcon, CalendarIcon, LockClosedIcon,
    ExportVchTable, ExportVchRecord,
    SystemLockModal,
  },
})
export default class ExportVch extends Vue {

  @Prop({default: null})
  user!: Person;

  months: Month[] = [];
  selectedMonth: Month | null = null;

  SystemLockReason: any = SystemLockReason;

  loading = true;

  recordRefreshKey = 1000;

  created() {
    const { onResult } = useQuery(GetMonthsDocument);

    onResult(queryResult => {
      this.months = queryResult.data.getMonths;

      const currentMonth = format(new Date(), 'y-MM');
      this.months.map((month, index) => {
        if ( month.key == currentMonth ) {
          this.selectedMonth = this.months[index];
        }
      });

      this.loading = false;
    });
  }

  get dateFrom() {
    return startOfMonth(new Date(`${this.selectedMonth!.key}-01`));
  }

  get dateTo() {
    return endOfMonth(new Date(`${this.selectedMonth!.key}-01`));
  }

  formatDate(date: Date) {
    return format(date, 'y-MM-dd');
  }

  lockState() {
    const systemLockModal = this.$refs.systemLockModal as SystemLockModal;
    systemLockModal.openModal();
  }

  async createRecord() {
    const response = await useApolloClient().client.mutate({
      mutation: CreateVchReportRecordDocument,
      variables: {
        month: this.selectedMonth!.key,
      }
    });

    if ( response.data?.createVchReportRecord ) {
      useToast().success('Report has been created.');
    }

    this.recordRefreshKey += 1;
  }

  openRecord() {
    if ( !this.user.apiKey ) {
      alert('You have no API key assigned.');
      return;
    }

    const reportUrl = `${process.env.VUE_APP_API_URL}/reports/vch?id=${this.selectedMonth!.key}&api-key=${this.user.apiKey}`;

    window.open(reportUrl, '_blank')!.focus();
  }

}
