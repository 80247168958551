
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AcceptSystemLockChangesDocument, CreateSystemLockDocument, ExtendSystemLockDocument, GetSystemLockStateDocument, SystemLockReason, SystemLockState } from '@/graphql/generated-operations';
import { format } from 'date-fns';

import AppButton from '@/components/Common/AppButton.vue';
import { CheckIcon } from '@heroicons/vue/solid';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useApolloClient } from '@vue/apollo-composable';
import { useToast } from 'vue-toastification';

@Options({
  components: {
    AppButton,
    CheckIcon,
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
  }
})
export default class SystemLockModal extends Vue {

  @Prop()
  systemLockReason!: SystemLockReason;

  @Prop()
  dateFrom!: string;

  @Prop()
  dateTo!: string;

  lockState: SystemLockState | undefined = undefined;
  lockedFrom: string | undefined;
  lockedTo: string | undefined;

  modalIsOpen = false;

  lockStateLabel() {
    switch ( this.lockState ) {
      case SystemLockState.Open: return 'Open';
      case SystemLockState.Locked: return 'Locked';
      case SystemLockState.LockedPartially: return 'Locked partially';
      case SystemLockState.Broken: return 'Broken';
      case SystemLockState.BrokenAndLockedPartially: return 'Broken and locked partially';
      default: return '';
    }
  }

  lockStateDescription() {
    switch ( this.lockState ) {
      case SystemLockState.Open: return 'Do you want to create lock for this date range?';
      case SystemLockState.Locked: return `Lock exists (${this.formatDate(this.lockedFrom)} - ${this.formatDate(this.lockedTo)}).`;
      case SystemLockState.LockedPartially: return `Locked partially (${this.formatDate(this.lockedFrom)} - ${this.formatDate(this.lockedTo)}). Do you want to extend the lock?`;
      case SystemLockState.Broken: return 'Do you want to accept the changes made in the locked inputs?';
      case SystemLockState.BrokenAndLockedPartially: return `Broken and locked partially (${this.formatDate(this.lockedFrom)} - ${this.formatDate(this.lockedTo)}). Do you want to extend the lock and accept the changes made in the locked inputs?`;
      default: return '';
    }
  }

  lockReasonName() {
    switch ( this.systemLockReason ) {
      case SystemLockReason.ProjtoolReported: return 'ProjTool Reported';
      case SystemLockReason.IcoReported: return 'ICO Reported';
      case SystemLockReason.VchReported: return 'VCH Reported';
      case SystemLockReason.AbraWorkReported: return 'ABRA Work Reported';
      case SystemLockReason.AbraAbsReported: return 'ABRA Abs Reported';
      default: return '';
    }
  }

  formatDate(date: string | undefined) {
    if ( !date ) return '';

    return format(new Date(date), 'd.M.Y');
  }

  async openModal(displayLockedState = true) {
    const query = await useApolloClient().client.query({
      query: GetSystemLockStateDocument,
      variables: {
        reason: this.systemLockReason,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      }
    });

    const data = query.data.getSystemLockState;
    this.lockState = data.state;
    this.lockedFrom = data.dateFrom ?? undefined;
    this.lockedTo = data.dateTo ?? undefined;

    if ( this.lockState == SystemLockState.Locked && !displayLockedState ) {
      return;
    }

    this.modalIsOpen = true;
  }

  closeModal() {
    this.modalIsOpen = false;
  }

  async confirmModal() {
    switch ( this.lockState ) {
      case SystemLockState.Open: {
        await this.createSystemLock();
        break;
      }
      case SystemLockState.Locked: break;
      case SystemLockState.LockedPartially: {
        await this.extendSystemLock();
        break;
      }
      case SystemLockState.Broken: {
        await this.acceptSystemLockChanges();
        break;
      }
      case SystemLockState.BrokenAndLockedPartially: {
        await this.extendSystemLock();
        await this.acceptSystemLockChanges();
        break;
      }
    }

    this.modalIsOpen = false;
  }

  async createSystemLock() {
    const response = await useApolloClient().client.mutate({
      mutation: CreateSystemLockDocument,
      variables: {
        reason: this.systemLockReason,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      }
    });

    if ( response.data?.createSystemLock ) {
      useToast().success('Lock has been created.');
    }
  }

  async extendSystemLock() {
    const response = await useApolloClient().client.mutate({
      mutation: ExtendSystemLockDocument,
      variables: {
        reason: this.systemLockReason,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      }
    });

    if ( response.data?.extendSystemLock ) {
      useToast().success('Lock has been extended.');
    }
  }

  async acceptSystemLockChanges() {
    const response =  await useApolloClient().client.mutate({
      mutation: AcceptSystemLockChangesDocument,
      variables: {
        reason: this.systemLockReason,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      }
    });

    if ( response.data?.acceptSystemLockChanges ) {
      useToast().success('Changes to the lock has been accepted.');
    }
  }


}
