
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AppButton from '@/components/Common/AppButton.vue';
import { useQuery } from '@vue/apollo-composable';
import { GetGroupedProjectsDocument, Month, Person } from '@/graphql/generated-operations';

@Options({
  components: {
    AppButton,
  }
})
export default class ProjectList extends Vue {

  @Prop({default: null})
  user!: Person;

  @Prop()
  month!: Month;

  groupedProjects: any[] = [];

  loading = true;

  created() {
    const { onResult } = useQuery(GetGroupedProjectsDocument, {
      date: `${this.month.key}-01`,
      personID: this.user.id,
    });

    onResult(queryResult => {
      this.groupedProjects = queryResult.data.getGroupedProjects;
      this.loading = false;
    });
  }

}
