
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AppButton from '@/components/Common/AppButton.vue';
import { useQuery } from '@vue/apollo-composable';
import { GetProjToolReportDocument, ProjToolReport } from '@/graphql/generated-operations';
import { formatTime } from '@/helpers/formatTime';

@Options({
  components: {
    AppButton,
  }
})
export default class ExportProjToolTable extends Vue {

  @Prop()
  dateFrom!: string;

  @Prop()
  dateTo!: string;

  loading = true;

  report: ProjToolReport[] = [];

  created() {
    const { onResult: onDataResult } = useQuery(GetProjToolReportDocument, {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    });

    onDataResult(queryResult => {
      this.report = queryResult.data.getProjToolReport;

      this.loading = false;
    });
  }

  getTime(minutes: number)
  {
    return formatTime({
      hours: Math.floor(minutes / 60),
      minutes: minutes % 60,
    });
  }

}
