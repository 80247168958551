import { useToast } from 'vue-toastification';

export const processResponseErrors = (response: string[] | undefined): boolean => {
  if (!response) {
    useToast().error('Internal server error.');
    return false;
  }

  if (response.length > 0) {
    for (const error of response) {
      useToast().error(error);
    }
    return false;
  }

  return true;
}
