
export abstract class AuthToken {
  public static readonly AUTH_TOKEN_KEY = 'auth-token';

  public static getAuthToken() {
    return localStorage.getItem(this.AUTH_TOKEN_KEY) || '';
  }

  public static hasAuthToken() {
    return this.getAuthToken().length > 0;
  }

  public static setAuthToken(token: string) {
    localStorage.setItem(this.AUTH_TOKEN_KEY, token);
  }

  public static clearAuthToken() {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
  }
}
