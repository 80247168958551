
import { Vue } from "vue-class-component";

import { Prop } from 'vue-property-decorator';
export default class AppButton extends Vue {

  @Prop()
  type!: string;

  @Prop()
  padding = 'normal';

  created() {
    this.checkDisabled();
  }

  updated() {
    this.checkDisabled();
  }

  checkDisabled() {
    const button = this.$refs.button as any;

    if ( button ) {
      if ( this.type.includes('disabled') ) {
        button.disabled = true;
      }
      else {
        button.disabled = false;
      }
    }
  }
}
