import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xs font-medium text-center py-1 rounded-2xl border text-green-600 bg-green-100 border-green-300"
}
const _hoisted_2 = {
  key: 0,
  class: "inline-block"
}
const _hoisted_3 = { class: "relative flex items-center justify-center py-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.timeToMinutes(_ctx.allocatedTime) === 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.labels)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Reported time: "))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.formatTime(_ctx.reportedTime)), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["bg-gray-100 border rounded-2xl overflow-hidden", _ctx.reportProgress == 0 ? 'border-red-300' : _ctx.reportProgress == 1 ? 'border-green-300' : 'border-yellow-300'])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["absolute top-0 bottom-0 left-0 rounded-2xl", _ctx.reportProgress == 0 ? 'bg-red-100' : _ctx.reportProgress == 1 ? 'bg-green-100' : 'bg-yellow-100']),
            style: _normalizeStyle('width: ' + Math.min(1, _ctx.reportProgress) * 100 + '%;')
          }, null, 6),
          _createElementVNode("div", {
            class: _normalizeClass(["relative w-full flex justify-between font-medium text-xs", _ctx.reportProgress == 0 ? 'text-red-600' : _ctx.reportProgress == 1 ? 'text-green-600' : 'text-yellow-600'])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["pl-3 text-left", !_ctx.labels && 'w-20'])
            }, [
              (_ctx.labels)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Reported time: "))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.formatTime(_ctx.reportedTime)), 1)
            ], 2),
            _createElementVNode("span", {
              class: _normalizeClass(["px-3 text-center", !_ctx.labels && 'w-2'])
            }, "/", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["pr-3 text-right", !_ctx.labels && 'w-20'])
            }, [
              (_ctx.labels)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Allocated time: "))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.formatTime(_ctx.allocatedTime)), 1)
            ], 2)
          ], 2)
        ])
      ], 2))
}