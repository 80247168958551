import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "min-w-full border overflow-x-auto border-b border-gray-200 rounded-md" }
const _hoisted_3 = { class: "table-auto min-w-full divide-y divide-gray-200" }
const _hoisted_4 = { class: "bg-gray-50" }
const _hoisted_5 = {
  colspan: "2",
  class: "px-4 py-3 text-left font-medium text-gray-800"
}
const _hoisted_6 = { class: "flex align-middle items-center" }
const _hoisted_7 = { class: "text-sm md:text-base" }
const _hoisted_8 = { class: "mx-2 text-xs font-semibold text-center p-1 rounded-md text-indigo-600 bg-indigo-100" }
const _hoisted_9 = { class: "bg-white divide-y divide-gray-200" }
const _hoisted_10 = { class: "w-auto md:w-52 px-2 py-3 whitespace-nowrap border-r" }
const _hoisted_11 = { class: "flex align-middle items-center" }
const _hoisted_12 = { class: "mx-2 text-xs font-semibold text-center p-1 rounded-md text-indigo-600 bg-indigo-100" }
const _hoisted_13 = { class: "px-2 py-3" }
const _hoisted_14 = { class: "flex align-middle items-center" }
const _hoisted_15 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Loading. "))
    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.groupedProjects, (projectGroup) => {
        return (_openBlock(), _createElementBlock("div", {
          key: projectGroup.id,
          class: "mb-4"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("table", _hoisted_3, [
              _createElementVNode("thead", _hoisted_4, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(projectGroup.name), 1),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(projectGroup.id), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("tbody", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projectGroup.projects, (project) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: project.id,
                    class: "hover:bg-gray-50"
                  }, [
                    _createElementVNode("td", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(project.id), 1)
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("a", {
                          href: 'https://melowntech.atlassian.net/browse/' + project.jiraKey,
                          target: "_blank",
                          title: "Open in Jira",
                          class: "text-xs md:text-sm lg:text-base text-blue-600"
                        }, _toDisplayString(project.name), 9, _hoisted_15)
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ]))
      }), 128))
}